<template>
	<v-layout pt-3 row wrap>
		<v-flex xs12>
			<v-badge color="transparent">
				<template v-slot:badge>
					<div class="badge-slot">
						<slot name="badge"></slot>
					</div>
				</template>
				<div v-if="versionNumber" class="body-1 font-weight-bold">V{{ versionNumber }} - {{ $translateDateTime(version.created_at, 'verylong') }}</div>
			</v-badge>
				<div v-if="author" :class="`caption grey--text text--${darkenOrLighten}-2`">{{ author.username }}</div>
				<ul v-for="applicationDocumentVersion in version.applicationDocumentVersions" :key="applicationDocumentVersion.id" class="application-document-version">
					<li>
						<div class="caption">{{ applicationDocumentVersion.application.title }}</div>
						<div class="body-1">
							{{ applicationDocumentVersion.status.name }}
							{{ applicationDocumentVersion.exception && applicationDocumentVersion.exception.message ? `(${applicationDocumentVersion.exception.message})` : '' }}
						</div>
					</li>
				</ul>
			<WModule name="workflow">
				<WorkflowStatusList :version="version"/>
			</WModule>
		</v-flex>
	</v-layout>
</template>
<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import GlobalParameterService from '@/services/GlobalParameter/GlobalParameterService'
import { mapState } from "vuex";

export default {
	name: 'HistoricVersion',
	components: {
		WorkflowStatusList: () => ({
			component: import('@/components/Workflows/WorkflowStatusList')
		})
	},
	mixins: [DocumentVersionsModuleGuard],
	props: {
		version: {
			type: Object,
			required: false,
			default: null
		},
		versionNumber: {
			type: Number,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			mailAttachmentMaxSize: null
		}
	},
	computed: {
		...mapState({
			isDarkModeEnabled: state => state.user.darkMode
		}),
		author: function () {
			return this.version?.author
		},
		darkenOrLighten() {
			return this.isDarkModeEnabled ? 'lighten' : 'darken'
		}
	},
	watch: {
		version: {
			deep: true,
			handler () {}
		}
	},
	mounted: function () {
		this.loadDocumentVersionAuthor()
		this.loadMailAttachmentMaxSize()
	},
	methods: {
		loadDocumentVersionAuthor: function () {
			if (this.version && !this.version.hasOwnProperty('author') && this.version.user_id) {
				this.service.getDocumentVersionAuthor(this.vendorId, this.version.document_id, this.version)
			}
		},
		loadMailAttachmentMaxSize: function () {
			GlobalParameterService.getGlobalParameter('mailAttachmentMaxSize').then(max => {
				this.mailAttachmentMaxSize = max.value
			})
		}
	}
}
</script>

<style scoped>
.badge-slot {
	width: 24px;
	height: 24px;
}
.application-document-version {
	list-style-type: none;
	font-style: italic;
}
</style>
